import React from 'react'
import {
  Section,
  Wysiwyg,
  Container,
  Button,
  Heading,
} from 'src/components/Base'
import { Hero } from 'src/sections'
import { cSection, cHeading, cJobButton } from './styles.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
import Seo from 'src/components/Seo'

const Job = (props) => {
  const jobData = useStaticQuery(graphql`
    query paginationJobsQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            atspCustomStringTranslations {
              translationJobs {
                txtApply
              }
            }
            atspJobOptions {
              singleJobHeaderImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              singleJobsHeaderLocation {
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                location
                hasoverlay
              }
            }
          }
        }
      }
    }
  `)

  let imageData =
    jobData.allWp.nodes[0].themeGeneralSettings.atspJobOptions
      .singleJobHeaderImage.localFile
  let hasOverlay = false
  let jobHeroMobile = false
  let jobHeroDesktop = false
  const isBrowser = typeof window !== 'undefined'

  // 1024 because of the iPad Pro
  if (isBrowser) {
    if (window.innerWidth <= 1024) {
      jobHeroMobile = true
    } else {
      jobHeroDesktop = true
    }
  }
  jobData.allWp.nodes[0].themeGeneralSettings.atspJobOptions.singleJobsHeaderLocation.forEach(
    (headerData) => {
      if (headerData.location === props.pageContext.location) {
        imageData = headerData.image.localFile
      }
    }
  )

  return (
    <>
      <Seo title={props.pageContext.jobTitle} />
      <Hero
        imageData={imageData}
        heading=""
        hasOverlay={hasOverlay}
        jobHero={jobHeroDesktop}
        jobHeroMobile={jobHeroMobile}
      />
      <Section className={cSection}>
        <Container>
          <Heading color="dark" as="h1" size={2} className={cHeading}>
            {props.pageContext.jobTitle}
          </Heading>
          <Wysiwyg>{props.pageContext.jobDescription}</Wysiwyg>
          <Button
            as={'a'}
            color="cta"
            className={cJobButton}
            // href={`https://career5.successfactors.eu/careers?company=arzallgemeP2&lang=de_DE&clientId=jobs2web&socialApply=false&career_ns=job_application&site=&career_job_req_id=${props.pageContext.jobReqId}&jobPipeline=Other&isInternalUser=false`}
            href={`https://career55.sapsf.eu/career?company=atsoluti01&lang=de_DE&clientId=jobs2web&socialApply=false&career_ns=job_application&site=&career_job_req_id=${props.pageContext.jobReqId}&jobPipeline=Other&isInternalUser=false`}
            target="blank"
            rel="noopener noreferrer"
          >
            {
              jobData.allWp.nodes[0].themeGeneralSettings
                .atspCustomStringTranslations.translationJobs.txtApply
            }
          </Button>
        </Container>
      </Section>
    </>
  )
}

export default Job
